import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Storage } from '@ionic/storage-angular';
import { environment } from '../../environments/environment';
import { UserService } from 'src/app/services/user.service';
import { BehaviorSubject } from 'rxjs';
import { PaymentService } from './payment.service';

@Injectable({ providedIn: 'root' })
export class SubscriptionService {
    public paymentNonce = new BehaviorSubject<boolean>(false);
    private userApiUrl = environment.claimApi;

    constructor(
        public http: HttpClient,
        private userService: UserService,
        private paymentService: PaymentService
    ) {}

    async create(account_id, subscriptionData) {    
        var optionsUrl = `${this.userApiUrl}/account/${account_id}/subscription`;
        return this.http.post(optionsUrl, {
            type: 'kilnlink',
            planId: subscriptionData.frequency, 
            nonce: this.paymentService.getNonce(), 
            billingCycles: (subscriptionData.renewals || 1), 
            numberControllers: subscriptionData.numberControllers
        }, this.headersWithAuthToken(false)).toPromise();          
    }

    async update(account_id, subscription_id, subscriptionData) {
        var optionsUrl = `${this.userApiUrl}/account/${account_id}/subscription/${subscription_id}`;
        return this.http.put(optionsUrl, {
            type: 'kilnlink',
            planId: subscriptionData.frequency, 
            nonce: this.paymentService.getNonce(), 
            billingCycles: (subscriptionData.renewals || 1), 
            numberControllers: subscriptionData.numberControllers
        }, this.headersWithAuthToken(false)).toPromise();          
    }

    async delete(account_id, subscription_id) {
        var optionsUrl = `${this.userApiUrl}/account/${account_id}/subscription/${subscription_id}`;
        return this.http.delete(optionsUrl, this.headersWithAuthToken(false)).toPromise()
    }

    async get(account_id) {
        var optionsUrl = `${this.userApiUrl}/account/${account_id}/subscription`;
        return this.http.get(optionsUrl, this.headersWithAuthToken(false)).toPromise();   
    }

    private headersWithAuthToken(includeAppNameToken = true) {
        const loginData = this.userService.getLoginData();

        if(loginData) {
            var headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-access-token': loginData.token
            };

            if(includeAppNameToken) { headers['x-app-name-token'] = 'kiln-link'; }
            const httpOptions = {
            headers: new HttpHeaders(headers)
            }

            return httpOptions;
        } 
    }
}