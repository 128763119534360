import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoadingController, AlertController, Platform } from '@ionic/angular';
import { UserService } from 'src/app/services/user.service';
import {Observable, BehaviorSubject, timer, from, throwError, of} from "rxjs";
import moment from 'moment';
import { LocalNotifications } from '@awesome-cordova-plugins/local-notifications/ngx';
import { Insomnia } from '@awesome-cordova-plugins/insomnia/ngx';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class KilnService {
  private claimUrl = environment.claimApi;
  private controllerUrl = environment.kilnApi;
  private subcriptionTimerKilnUpdate: any;
  private subcriptionTimerFlash: any;
  public kilnsData;
  public kilnIdsToName = {};
  public selectedKilnAlarm: alarmType = {
    active: false,
    text:'',
    class:''
  };
  public selectedKilnName = '';
  public selectedKilnId;
  public updateTime = 1;
  public lastAlarmState = 'OFF';
  public stayAwake = false; //current state of stay awake control
  public stayAwakeToggle = false; //current state of stay awake toggle - saved here for leaving and reentering view page
  public selectedKiln = null;
  public currently_viewing;
  public fetching = [];
  public fetchingStatuses = [];
  public dataFetchingStatus = new BehaviorSubject<any>(false);
  public kilnInfo = new BehaviorSubject<any>({});
  public timerStatus = new BehaviorSubject<any>({});
  public addKilnUpdate = new BehaviorSubject<any>({});
  
  constructor(public http: HttpClient,
    private plt: Platform,
    public alertCtrl: AlertController,
    public userService: UserService,
    public loadingController:LoadingController,
    private localNotifications: LocalNotifications,
    private insomnia: Insomnia
  ) {
    this.plt.ready().then((readySource) => {
      this.localNotifications.on('click').subscribe((notification) => {
        this.onNotificationClick(notification);
      });
    });

    this.startTimerSecond();
  }

  public startTimerSecond() { // This timer is used to flash messages and as the time base for the update timer
    if(!this.subcriptionTimerFlash) {
      let TimerSecond = timer(1000, 4000);
      this.subcriptionTimerFlash = TimerSecond.subscribe(t => this.onTickTimerSecond(t));
    } 
  }

  public killTimerSecond() {
    if(this.subcriptionTimerKilnUpdate) {
      this.subcriptionTimerKilnUpdate.unsubscribe();
    }
  }

  public onTickTimerSecond(tick){
    var loginData;
    if (this.selectedKilnAlarm.active){
      if (tick%2){
        this.timerStatus.next(this.selectedKilnAlarm.class)
      } else {
        this.timerStatus.next('item-alarm-off'); //flash off
      }
    }

    if (this.userService.LoggedIn()) {
      this.updateTime--;
      // console.log(`timerTickSecond, ${tick}, updateTime: ${this.updateTime}, should update? ${this.updateTime <= 0}`)
      if (this.updateTime <= 0) {
        //timed for update
        this.updateTime = 10; //reset the timer to 10 seconds
        this.dataFetchingStatus.next(true);
        loginData = this.userService.getLoginData();
        loginData = (loginData && loginData.loggedInAs) ? loginData.loggedInAs : (loginData || {});
        var ids = []
        if(loginData.kiln_info) {
          loginData.kiln_info.forEach((kiln) => {
            if(kiln && !this.kilnIdsToName[kiln.id]) {
              this.kilnIdsToName[kiln.id] = {name: kiln.name, role: kiln.role};
            } else if(kiln && this.kilnIdsToName[kiln.id]) {
              this.kilnIdsToName[kiln.id].name = kiln.name || this.kilnIdsToName[kiln.id].name;
              this.kilnIdsToName[kiln.id].role = kiln.role;
            }
          });
          ids = loginData.kiln_info.map(id => id.id);
        }

        // console.log(`onTickTimerSecond: account, ${loginData.account}, ids: ${ids}`);
        this.getKilnData(loginData.email, loginData.token, ids);
        if(this.currently_viewing) { this.getSingleKilnData(this.currently_viewing); }
      }
    }
  }

  public setTimerSecond(time: number){
    this.updateTime = time;
  }

  public getUsersForKiln(kiln_id, mac, id): Promise<any>  {
    if(environment.show_premium && mac !== 'na') {
      if(this.selectedKiln || kiln_id) {
        var kilnUrl = `${this.claimUrl}/premium/kilns/${kiln_id || 'na'}/users`;
        return this.http.post(kilnUrl, {mac_address: mac, kiln_id: id}, this.headersWithAuthToken(false))
            .toPromise()
      }
    } else {
      if(this.selectedKiln || kiln_id) {
        var kilnUrl = `${this.claimUrl}/kilns/${kiln_id}`;
        return this.http.get(kilnUrl, this.headersWithAuthToken(false))
            .toPromise()
      }
    }
  }

  public getSingleKilnData(id): Promise<any>  {
    return new Promise((resolve, reject) => {
      this.currently_viewing = id;
      if(id) {
        var kilnUrl = this.controllerUrl + "/kilns/view";
        let getSingleDataPromise = this.http.post(kilnUrl, {ids: [id]}, this.headersWithAuthToken(true))
        
        getSingleDataPromise.subscribe((res) => {
          this.dataFetchingStatus.next(false);
          this.getSingleKilnDataSuccess(res)
          resolve(res['kilns']);
        }, (err) => {
          console.log(err);
          reject();
        })
      } else {
        console.log('no id')
        reject()
      }
    })
  }  

  public getFiringHistory(mac, serial, page = 0): Promise<any>  {
    var kilnUrl = this.controllerUrl + "/kilns/history";
    return this.http.post(kilnUrl, {mac_address: mac, serial_number: serial, page: page, limit: true}, this.headersWithAuthToken(true))
        .toPromise()
  }

  public getFiringDetails(firing_id, t_scale = 'F', nostatus = false): Promise<any>  {
    return new Promise((resolve, reject) => {        
      if (firing_id && firing_id !== 'undefined_undefined_undefined' && !this.fetching.includes(firing_id)) {
        // var kilnUrl = nostatus ? `${this.controllerUrl}/firings/${firing_id}?nostatus=true` : `${this.controllerUrl}/firings/${firing_id}`;
        var kilnUrl = `${this.controllerUrl}/firings/${firing_id}`;
        this.fetching.push(firing_id);

        let firingHistoryPromise = this.http.get(kilnUrl, this.headersWithAuthToken(true))        
        firingHistoryPromise.subscribe(
          (res) => {
            t_scale = res && res['config'] ? res['config']['t_scale'] : t_scale;
            let response = this.getFiringDetailsSuccess(res, firing_id, t_scale);
            this.fetching = this.fetching.filter((id) => id !== firing_id);
            resolve(response);
          }, (error) => {
            console.log(`getFiringDetails error for ${firing_id}, | ${JSON.stringify(error)}`)
            this.fetching = this.fetching.filter((id) => id !== firing_id);
            // this.events.publish(`getFiringDetails${firing_id}:error`, error)
            reject(error);
          });
      } else {
        reject();
        // console.log(`not getting firing details for ${firing_id} ${this.fetching.includes(firing_id) ? 'already waiting for a response' : ''}`)
      }
    });
  }

  public getPaginatedStatuses(firing_id, t_scale = 'F'): Promise<any>  {
    // console.log(`getPaginatedStatuses`)
    return new Promise((resolve, reject) => {
      if(firing_id && firing_id !== 'undefined_undefined_undefined' && !this.fetchingStatuses.includes(firing_id)) {
        var kilnUrl = `${this.controllerUrl}/details/${firing_id}`;
        let headers = this.headersWithAuthToken(true)
        this.fetchingStatuses.push(firing_id)
        let paginatedStatusesPromise = this.http.post(kilnUrl, headers)
        paginatedStatusesPromise.subscribe(
          res => {
            t_scale = res && res['config'] ? res['config']['t_scale'] : t_scale;
            let response = this.getFiringDetailsSuccess(res, firing_id, t_scale);
            this.fetchingStatuses = this.fetchingStatuses.filter((id) => id !== firing_id);
            resolve(response);
          }, error => {
            console.log(`getFiringDetails error for ${firing_id}, | ${JSON.stringify(error)}`)
            this.fetchingStatuses = this.fetchingStatuses.filter((id) => id !== firing_id);
            reject(error);
          });
      } else {
        console.log(`not getting firing details for ${firing_id}`)
        resolve({});
      }
    })
  }

  public getDiagnosticInformation(kilnId): Promise<any>  {
    var kilnUrl = `${this.controllerUrl}/kilns/${kilnId}/diagnostics`;
    return this.http.get(kilnUrl, this.headersWithAuthToken(true))
        .toPromise()
  }

  public getKilnNames() {
    return this.kilnIdsToName;
  }

  public replaceBisqueGlazeAndPreheat(kiln) {
    let name = kiln && kiln.program && kiln.program.name;
    if (name && (name === "Unknown" || name === "Bisque" || name === "Glaze" || name === "Preheat")) {
      if (kiln.program.cone && kiln.program.cone.toLowerCase().startsWith('cone')) name = `${kiln.program.cone}-${kiln.program.speed}`;
      else if (kiln.program.type && kiln.program.type.toLowerCase().startsWith('ramp')) name = `User Pgm#${kiln.program.custom_number}`;
      else { name = ''; }
      kiln.program.name = name;
    }

    let type = kiln && kiln.program && kiln.program.type;
    if (type && (kiln.program.type == "Unknown" || kiln.program.type === "Bisque" || kiln.program.type === "Glaze" || kiln.program.type === "Preheat")){
      if (kiln.program.cone && kiln.program.cone.toLowerCase().startsWith('cone')) type = `${kiln.program.cone}-${kiln.program.speed}`;
      else if (kiln.program.type && kiln.program.type.toLowerCase().startsWith('ramp')) type = `User Pgm#${kiln.program.custom_number}`;
      else { type = ''; }
      kiln.program.type = type;
    }
  }

  public getSingleKilnDataSuccess(response) {
    if (response.kilns && response.kilns.length === 1) {
      var kiln = response.kilns[0]
      if(this.kilnIdsToName[kiln['serial_number']] && kiln) {
        kiln.name = this.kilnIdsToName[kiln['serial_number']].name ? this.kilnIdsToName[kiln['serial_number']].name : kiln.name;
        kiln.role = this.kilnIdsToName[kiln['serial_number']].role ? this.kilnIdsToName[kiln['serial_number']].role : "user";
      }

      kiln = this.convertTemps(kiln);
      this.replaceBisqueGlazeAndPreheat(kiln);
      if (kiln) {
        this.extractSelectedKiln(kiln, this.selectedKilnId);
      };

      // this.events.publish('getSingleKilnData:success', [kiln]);
    } else {
      let msg = (response && response.kilns && response.kilns.length === 0) ? "No kilns returned": 'We could not get data for this kiln at this time.';
      // this.events.publish('getSingleKilnData:error', msg);
    }

    //fix the program names for migrated myskutt data
    if (response && response.kilns && response.kilns.length > 0){
      response.kilns.forEach(kiln => {
        if (kiln.program && kiln.program.name && (kiln.program.name === "Unknown" || kiln.program.name === "Bisque" || kiln.program.name === "Glaze" || kiln.program.name === "Preheat")){
          if (kiln.program && kiln.program.cone && kiln.program.cone.toLowerCase().startsWith('cone')) kiln.program.name = `${kiln.program.cone}-${kiln.program.speed}`;
          else if (kiln.program && kiln.program.type && kiln.program.type.toLowerCase().startsWith('ramp')) kiln.program.name = `User Pgm#${kiln.program.custom_number}`;
          else { kiln.program.name = ''; }
        }
      });
    }
  }

  public getKilnData(email: string, token: string, controller_ids, publish = true) {    
    let loginData = this.userService.getLoginData()
    if(controller_ids && (controller_ids.length > 0 || (loginData && loginData.role === "super-admin"))) {
      if(this.currently_viewing && !controller_ids.find((ctrl_id) => ctrl_id === this.currently_viewing)) { controller_ids.push(this.currently_viewing); }
      var kilnUrl = this.controllerUrl + "/kilns/slim";
      let kilnDataPromise = this.http.post(kilnUrl, {ids: controller_ids}, this.headersWithAuthToken(true))
      
      kilnDataPromise.subscribe(
          res => {
            this.dataFetchingStatus.next(false);
            this.getKilnDataSuccess(res, controller_ids, publish)
      });
    }
  }

  private getKilnDataSuccess(response, controller_ids, publish) {
    try {
      var now = new Date();
      now.setTime(Date.now());

      if (response.kilns) {
        if(!this.kilnsData) {
          this.kilnsData = [];
        } else {
          this.kilnsData = this.kilnsData.filter((k) => controller_ids.includes(k.serial_number));
        }

        controller_ids.forEach((id) => {
          var kilnStatus = response.kilns.find((kiln) => kiln['serial_number'] == id)
          if(!kilnStatus) {
            kilnStatus = {name: "N/A", mode: 'Not Connected', serial_number: id, external_id: id, config: {}, program: {}, noStatusFromKilnApi: true};
          }

          if(this.kilnIdsToName[id] && kilnStatus) {
            kilnStatus.name = this.kilnIdsToName[id].name ? this.kilnIdsToName[id].name : kilnStatus.name;
            kilnStatus.role = this.kilnIdsToName[id].role ? this.kilnIdsToName[id].role : "user";
          }

          kilnStatus = this.convertTemps(kilnStatus);
          this.replaceBisqueGlazeAndPreheat(kilnStatus);
          var index = this.kilnsData.findIndex(item => item.serial_number == kilnStatus.serial_number);

          if(index > -1) {
            this.kilnsData.splice(index, 1, kilnStatus);
          } else {
            this.kilnsData.push(kilnStatus);
          }
        })

        this.kilnInfo.next(this.kilnsData)
      } else {
        this.kilnInfo.next({error: true})
      }
    } catch (error) {
      this.kilnInfo.next({error: true})
    }
  }

  public getFiringDetailsSuccess(res, firing_id, t_scale = 'F')  {
    if (res.statuses) {
      if(!res.cost && res.statuses && res.statuses[res.statuses.length - 1]) {
        res.cost = res.statuses[res.statuses.length - 1].cost;
        res.cost = (res.cost / 100);
      }

      if(['C', 'c'].includes(t_scale)) {
        var program_keys = ["alarm_t", "steps.t", "steps.rt"];
        var objectToUpdate = res.program;
        program_keys.forEach((k) => {
          this.updateTempToCInObject(objectToUpdate, k)}
        )
      }

      // console.log(res.statuses)
      res.statuses.forEach(status => {
        if (status.mode == 'Complete' || status.mode == 'Idle'){
          status.set_pt = 0; //clear the set point after the firing is complete
          status.step = "Cooling"; //clear the set point after the firing is complete
        } else {
           res.final_status = status.err_text;
        }

        if(['C', 'c'].includes(t_scale)) {
          status = this.convertFiringInformationToC(status);
        }
        if(!res.program) { res.program = {}; }
        if (!res.program.max_temp || (res.program && status && status.t2 > res.program.max_temp)) res.program.max_temp = status.t2;
      });
      if (res.final_status) {
        //filter out 'CLR returns to idle' from error messages
        var n = res.final_status.search('CLR');
        if (n > 0){
          res.final_status = res.final_status.substring(0, n);
        }
      }
    //  console.log("final status: " + res.final_status);
    }
    //check for unknown program name in migrated myskutt data
    if (res.program && res.program.name && (res.program.name === "Unknown" || res.program.name === "Bisque" || res.program.name === "Glaze" || res.program.name === "Preheat")){
      if (res.program.cone && res.program.cone.toLowerCase().startsWith('cone')) res.program.name = `${res.program.cone}-${res.program.speed}`;
      else if (res.program.type && res.program.type.toLowerCase().startsWith('ramp')) res.program.name = `User Pgm#${res.program.custom_number}`;
      else { res.program.name = ''; }
    }

    return res;
  }

  public logInAs() {
    this.currently_viewing = undefined;
    this.kilnsData = [];
    this.fetching = [];
    this.dataFetchingStatus.next(true);
  }

  public logOut() {
    this.currently_viewing = undefined;
    this.kilnsData = [];
    this.fetching = [];
  }

  public getkilnsData(){
    return this.kilnsData;
  }

  public getKilnsDataForUser() {
    var loginData = this.userService.getLoginData();
    if(loginData) {
      loginData = loginData.loggedInAs || loginData;
      var ids = loginData.kiln_info ? loginData.kiln_info.map(id => id.id) : [];
      // console.log(`ids the user has access to: ${ids}`)
      return this.kilnsData ? this.kilnsData.filter((k) => ids.includes(k.serial_number)) : [];
    } else {
      return [];
    }
  }

  updateTempToCInObject(obj, key) {
    if(obj) {
      if(key.includes(".")) {
        var splitKey = key.split(".");
        this.updateTempToCInObject(obj[splitKey[0]], splitKey[1]);
      } else {
        if(Array.isArray(obj)) {
          obj.forEach((array_obj) => {
            let converted = this.convertToC(array_obj[key]);
            if(key === 'rt') { converted = Math.round((array_obj[key]) * 5 / 9) }
            array_obj[key] = converted;
          });
        } else {
          let converted = this.convertToC(obj[key]);
          obj[key] = converted;
        }
      }
    }
  }

  public convertTemps(kiln) {
    var timePassed = moment.duration(moment().diff(moment(kiln.updatedAt)));
    var fiveMinutes = moment.duration(5, 'minutes');
    if(kiln.status && kiln.status.mode && timePassed > fiveMinutes) {
      kiln.status.mode = "Not Connected";
    }

    if (kiln.t_scale === 'C' || (kiln.config && kiln.config.t_scale === 'C')){
      var status_keys = ["t2", "t1", "t3", "diag.board_t"];
      var objectToUpdate = (kiln.status && kiln.status.t2) ? kiln.status : kiln;
      status_keys.forEach((k) => { this.updateTempToCInObject(objectToUpdate, k)} )

      var program_keys = ["alarm_t", "steps.t", "steps.rt"];
      var objectToUpdate = kiln.program;
      program_keys.forEach((k) => { this.updateTempToCInObject(objectToUpdate, k)} )

      var firing_keys = ["set_pt"];
      var objectToUpdate = kiln.status.firing;
      firing_keys.forEach((k) => { this.updateTempToCInObject(objectToUpdate, k)} )
    }

    return kiln;
  }

  public convertFiringInformationToC(status) {
    if(status) {
      var keys = ["board_t", "set_pt", "t1", "t2", "t3", "cone_offset", "max_temp"]
      keys.forEach((k) => {
        let converted = this.convertToC(status[k]);
        // console.log(`converting firing info temps for ${k} from ${status[k]} => ${converted}`)
        status[k] = converted;
      })
    }

    return status;
  }

  public convertToC (temperature){
    return Math.round((temperature - 32) * 5 / 9); //convert to C equivalent
  }

  public unselectKiln() {
    this.selectedKilnName = '';
    this.selectedKilnId = null;
    this.selectedKiln = null;
  }

  public extractSelectedKiln(selectedKilnName, external_id) {
    this.selectedKilnName = selectedKilnName;
    this.selectedKilnId = external_id;
    var kilnMatch = this.kilnsData.find((kiln) => {
      return external_id == kiln.serial_number;
    });

    if (kilnMatch) {
      this.selectedKiln = kilnMatch;
      if (typeof (kilnMatch.status && kilnMatch.status.firing && kilnMatch.status.firing.alarm) !== 'undefined') {
        this.selectedKilnAlarm = this.CheckAlarms(kilnMatch.status.firing.alarm);
      }

      return this.selectedKiln;
    } else {
      return false;
    }
  }

  public CheckAlarms = function(alarm) {
    var alarmInfo = {active: false, text:'', class:''};
    if (alarm == null) alarm = 'OFF'; //catch older versions of fw that don't send alarm status
    var alarmStatuses = {
      'OFF': {text: 'No Alarm', active: false, class: 'item-alarm-off'},
      'ALM': {text: 'Error!', active: true, class: 'item-alarm-error'},
      'BOH': {text: 'Beginning of Hold', active: true, class: 'item-alarm-hold-notice'},
      'EOH': {text: 'End of Hold', active: true, class: 'item-alarm-hold-notice'}
    }
    if (alarmStatuses.hasOwnProperty[alarm]) {
        alarmInfo = alarmStatuses[alarm]
    } else {
        alarmInfo = {text: '', active: false, class: 'item-alarm-error'};
    }

    this.lastAlarmState = alarm; //store the alarm state for next check
    return alarmInfo;
  }

  public addKilnById(id: string, claim_for = null, name = null): Promise<any> {
    var kilnAddUrl = this.claimUrl + "/kilns/claim"
    var data = {external_id: id, name: name}

    data['users'] = claim_for ? [{email: claim_for}] : [{email: this.userService.getLoginData().email}];
    return new Promise((resolve, reject) => {
      let addKilnPromise = this.http.post(kilnAddUrl, data, this.headersWithAuthToken(false))
      addKilnPromise.subscribe(
        res => {
          res['name'] = res['name'] ? res['name'] : name;
          if(res['external_id']) { res['serial_number'] = res['external_id']; }
          this.addKilnSuccess(res);
          resolve({});
        }, error => {
          reject(error);
        });
    })
  }

  public addKiln(serialNum: string, name: string, claim_for = null): Promise<any> {
    var getKilnExternalIdUrl = `${this.controllerUrl}/kilns?serial_number=${serialNum}`;

    return new Promise((resolve, reject) => {
      let addKilnPromise = this.http.get(getKilnExternalIdUrl, this.headersWithAuthToken(true))
      addKilnPromise.subscribe(
      (kiln_id) => {
        this.addKilnById(serialNum, claim_for, name).then(resolve).catch(reject)
      },
      (error) => { this.addKilnById(serialNum, claim_for, name).then(resolve).catch(reject) });
    })
  }

  public addKilnSuccess(response){
    if (typeof(response.alert) !== 'undefined') {
      // this.events.publish('addKiln:error', response.alert);
      this.addKilnUpdate.next({error: response.alert})
    } else {
      this.userService.addKilnSuccess(response);
      this.setTimerSecond(-1);
      this.addKilnUpdate.next({error: false, data: response})
      // this.events.publish('addKiln:success', response);
    }
  }

  public updatePermissions(controllerId: string, userEmail: string, newGroup: string): Promise<any> {
    var editUrl = this.claimUrl + `/kilns/${controllerId}/permissions`;
    var kilnData = {user: userEmail, role: newGroup}

    if(controllerId) {
      return this.http.put(editUrl, kilnData, this.headersWithAuthToken(false))
        .toPromise()
    } else {
      return new Promise((r, rj) => rj())
    }
  }

  public updateKilnNameAndRoleData() {
    this.userService.setTimerSecond(0);
    this.userService.updateLoginData();
  }

  public editName(name: string, controllerId: string): Promise<any> {
    var editUrl = this.claimUrl + `/kilns/${controllerId}`;
    var kilnData = {"name": name}

    return new Promise((resolve, reject) => {
      let editNamePromise = this.http.put(editUrl, kilnData, this.headersWithAuthToken(false))
      editNamePromise.subscribe(
      res => {
        if(res["exception"]) {
          reject();
        } else {
          this.userService.updateKilnName(controllerId, name);
          this.kilnIdsToName[controllerId].name = res["name"];
          resolve(res);
        }
      });
    })
  }

  public unclaim(kilnId, hasElevatedPrivileges = false, unclaim_for_user = null, group = null): Promise<any> {
    var unclaimUrl = this.claimUrl + `/kilns/unclaim`

    var data = {external_id: kilnId}
    if(hasElevatedPrivileges) {
      data["unclaim_for"] = unclaim_for_user
    }

    return new Promise((resolve, reject) => {
      let unclaimPromise = this.http.post(unclaimUrl, data, this.headersWithAuthToken(false))
      unclaimPromise.subscribe(() => {
        this.userService.unclaim(kilnId, unclaim_for_user);
        resolve({});
      })
    })
  }

  public getFiringNotes(firing_id): Promise<any> {
    var getFiringNotesUrl = `${this.claimUrl}/premium/firing/${firing_id}/note`
    return this.http.get(getFiringNotesUrl, this.headersWithAuthToken(false))
      .toPromise()
  }

  public createFiringNote(firing_id, body): Promise<any> {
    var getFiringNotesUrl = `${this.claimUrl}/premium/firing/note`
    return this.http.post(getFiringNotesUrl, body, this.headersWithAuthToken(false))
      .toPromise()
  }

  public updateFiringNote(firing_id, body): Promise<any> {
    var getFiringNotesUrl = `${this.claimUrl}/premium/firing/note`
    return this.http.put(getFiringNotesUrl, body, this.headersWithAuthToken(false))
      .toPromise()
  }

  public kilnSearch(serial): Promise<any> {
    this.currently_viewing = serial;
    var searchUrl = `${this.claimUrl}/kilns/search`
    var loginData = this.userService.getLoginData();

    return new Promise((resolve, reject) => {
      let kilnSearchPromise = this.http.post(searchUrl, {serial: serial}, this.headersWithAuthToken(false))
      kilnSearchPromise.subscribe(res => {
        if(this.kilnIdsToName[res["external_id"]]) {
          this.kilnIdsToName[res["external_id"]].name = res["name"];
        } else {
          this.kilnIdsToName[res["external_id"]] = {name: res["name"], role: 'viewer'}
        }
        this.getKilnData(loginData.email, loginData.token, [serial], false)
        resolve(res);
      }, (err) => { reject(err); });
    });
  }

  public userSearch(username): Promise<any> {
    var searchUrl = `${this.claimUrl}/users/search`
    return this.http.post(searchUrl, {name: username}, this.headersWithAuthToken(false))
      .toPromise()
  }

  public registerSingleController(serial, mac): Promise<any> {
    var registerSingleControllerUrl = `${this.claimUrl}/kilns`
    return this.http.post(registerSingleControllerUrl,
        {serial_number: serial, mac_address: mac},
        this.headersWithAuthToken(false)
      ).toPromise()
  }

  public getKilnSidebarDetails(mac, id): Promise<any> {
    var detailsUrl = `${this.claimUrl}/premium/kilns`
    return this.http.post(detailsUrl, {mac_address: mac, kiln_id: id}, this.headersWithAuthToken(false))
      .toPromise()
  }

  public getCurrentFiringInformation(id) {
    var currentFiringDetailsUrl = `${this.controllerUrl}/v2/firings/${id}`
    // this.http.get(currentFiringDetailsUrl, this.headersWithAuthToken(false, 'registerController:failure'))
    //   .toPromise()
    //   .then(res => {
    //     this.events.publish('getCurrentFiringInformation:success', res)
    //   })
    //   .catch(error => this.events.publish('getCurrentFiringInformation:error', error));
  }

  private headersWithAuthToken(includeAppNameToken = true) {
    const loginData = this.userService.getLoginData();

    if(loginData) {
      var headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': loginData.token
      };

      if(includeAppNameToken) { headers['x-app-name-token'] = 'kiln-link'; }
      const httpOptions = {
        headers: new HttpHeaders(headers)
      }      
      
      return httpOptions;
    } 
  }

  async onNotificationClick (notification){
    var alarmTime = new Date();
    var notifyText: string;

    // let json = JSON.parse(notification.data);
    alarmTime.setTime(notification.at * 1000);
    // console.log('notification: ' + JSON.stringify(notification));
    // console.log('notification time: ' + notification.at);
    // console.log('alarm time: ' + alarmTime.toString());
    notifyText = notification.text + ' detected at ' + alarmTime.toString();
    notifyText += '. Actual alarm may have occurred earlier.';

     let alert = await this.alertCtrl.create({
       header: notification.title,
       subHeader: notifyText,
       buttons: ['Dismiss']
     });
     await alert.present();
  }

  public checkFirmwareVersion(current_firmware_version) {
    return new Observable((subscriber) => {
      this.http.post(`${environment.firmware_url}/firmware/update.json`,
        {
         "product_line": "kiln",
         "manufacturer": "skutt",
         "model": "GenesisLT3140",
         "version": current_firmware_version
      })
        .subscribe(async (response) => {
          if(response['update_available']) {
            let latest_version = (response && response["latest_version"]) ? response["latest_version"] : '2.6.6'
            subscriber.next(latest_version);
          } else {
            subscriber.next(current_firmware_version);
          }
        }, (e) => {
          console.log(`error getting firmware version ${e.message}`)
        })
    });
  }

  //check the state of the stay awake toggle
  public getStayAwakeToggle (){
    return this.stayAwakeToggle;
  }

  //set the state of the stay awake toggle
  public setStayAwakeToggle (awake: boolean){
    this.stayAwakeToggle = awake;
  }

  //check the current status of the stay awake control
  public stayAwakeIsActive (){
    return this.stayAwake;
  }

  //set the screen to stay awake or allow sleep
  public setStayAwake (awake: boolean){
    if (awake){
      //set to stay awake
      this.insomnia.keepAwake()
      .then(
        () => {
          // console.log('set to stay awake');
          this.stayAwake = true;
        },
        () => console.log('set stay awake failed')
      );
    } else {
      //set to allow sleep
      this.insomnia.allowSleepAgain()
      .then(
        () => {
          // console.log('set to allow sleep');
          this.stayAwake = false;
        },
        () => console.log('set allow sleep failed')
      );
    }
  }
}

//define the type for kiln information object
export interface kilnInfo {
    name: string,
    mode: string,
    modeClass: string,
    program: string,
    numberOfZones: number,
    temp1: string,
    temp2: string,
    temp3: string,
    setPoint: string,
    segment: string,
    holdRemaining: string,
    firingTime: string,
    timeStamp: string,
    tScale: string,
    errorText: string,
    is_premium?: boolean
}

//define the type for alarm status object
export interface alarmType {
  active: boolean,
  text: string,
  class?: string
};
