<table>
  <thead>
    <th>&nbsp;</th>
    <th>Total</th>
    <th>Free</th>
    <th>Premium</th>        
  </thead>
  <tbody>
    <tr>
      <td>Accounts</td>
      <td>&nbsp;</td>
      <td>&nbsp;</td>
      <td>{{ accounts.premium | number:'1.0':'en-US' }}</td>
    </tr>
    
    <tr>
      <td>Users</td>
      <td>{{ accounts.totalUsers | number:'1.0':'en-US' }}</td>
      <td>{{ accounts.free | number:'1.0':'en-US' }}</td>
      <td>{{ accounts.premium_users | number:'1.0':'en-US' }}</td>
    </tr>
    
    <tr>
      <td>Number Kilns</td>
      <td>{{ kilns.total | number:'1.0':'en-US' }}</td>
      <td>{{ kilns.free.total | number:'1.0':'en-US' }}</td>
      <td>{{ kilns.premium.total | number:'1.0':'en-US' }}</td>
    </tr>
    
    <tr>
      <td>Firings</td>
      <td>{{ kilns.firing | number:'1.0':'en-US' }}</td>   
      <td>{{ kilns.free.firing_count | number:'1.0':'en-US' }} </td>
      <td>{{ kilns.premium.firing_count | number:'1.0':'en-US' }}</td>        
    </tr>

    <tr>&nbsp;</tr>
    <tr>
      <th>Current Status (all):</th>
      <th># Online</th>
      <th># Offline (> 3months)</th>
      <th>&nbsp;</th>    
    </tr>
    <tr>
      <th>&nbsp;</th>
      <td>{{ kilns.online | number:'1.0':'en-US' }}</td>
      <td>{{ kilns.offline | number:'1.0':'en-US' }}</td>
      <td>&nbsp;</td>       
    </tr>
  </tbody>
</table>

<div>
  <h6>Firmware Versions</h6>
  <table *ngIf="kilns && kilns.fw_versions">
    <thead>
      <th>Version</th>
      <th>Number of Kilns</th>
    </thead>
    <tr *ngFor="let fw of kilns.fw_versions">
      <td>{{fw.key}}</td>
      <td>{{fw.value}}</td>
    </tr>
  </table>
</div>