import { Component, OnInit } from '@angular/core';
import { KilnService } from '../services/kiln.service';
import { AccountService } from '../services/account.service';

@Component({
  selector: 'app-admin-stats',
  templateUrl: './admin-stats.component.html',
  styleUrls: ['./admin-stats.component.css'],
})
export class AdminStatsComponent  implements OnInit {
  public kilns = {
    total: "",
    free: { total: "", firing_count: ""},
    premium: { total: 0, firing_count: ""},
    online: "",
    offline: "",
    firing: "",
    fw_versions: [], fw: {}
  }

  public accounts = {premium: "73", free: "938", loggedInUsers: "17", totalUsers: "379", premium_users: '37'}

  constructor(
    public kilnService: KilnService,
    public accountService: AccountService
  ) { }

  ngOnInit() {
    this.accountService.getFiringStatistics().then((statistics) => {
      if(statistics) {
        this.kilns.total = statistics.total;
        this.kilns.free = statistics.free;
        this.kilns.premium = statistics.premium;
        this.kilns.online = statistics.tenMinutes;
        this.kilns.offline = statistics.threeMonthAgo;
        this.kilns.firing = statistics.firing;
        this.kilns.fw_versions = Object.keys(statistics.fw).reduce((all, current) => {
          if(statistics.fw[current]) {
            all.push({key: current, value: statistics.fw[current]})
          } 
          return all;
        }, []);

        this.kilns.fw_versions = this.kilns.fw_versions.filter((version) => {return version.value > 10}).sort((a, b) => {return a.value >= b.value ? -1 : 1; })
        this.kilns.fw = statistics.fw;
      }
    }).catch((e) => {
      console.log(e);
    })

    this.accountService.premiumUserStatistics().then((data) => {
      this.accounts.premium = data.premium.total_accounts;
      this.accounts.premium_users = data.premium.total_users || 0;
      if(this.accounts.totalUsers) this.accounts.free = (parseInt(this.accounts.totalUsers) - parseInt(this.accounts.premium_users)).toString()
    });

    this.accountService.userCount().then((userCountResponse) => {
      this.accounts.totalUsers = userCountResponse.userCount || 0;
      if(this.accounts.premium_users) this.accounts.free = (parseInt(this.accounts.totalUsers) - parseInt(this.accounts.premium_users)).toString()
    })

    this.accountService.userEvents().then((userEvents) => {
      this.accounts.loggedInUsers = userEvents.signInSuccessCount 
      
    })
  }
}
