import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { UserService } from 'src/app/services/user.service';

@Injectable({ providedIn: 'root' })
export class AlertService {
  public elasticbeanstalk_uri = environment.claimApi;
  public local_uri = environment.claimApi;

  constructor(
    public http: HttpClient,
    public userService: UserService) {
      this.local_uri = this.elasticbeanstalk_uri;
  }

  public getAlertSettingsForKiln(kiln_id): Promise<any>{
    var alertUrl = `${this.local_uri}/premium/kilns/${kiln_id}/alertsettings`;
    return this.http.get(alertUrl, this.httpOptions())
      .toPromise()
  }

  public updateAlertSettings(kiln_id, data): Promise<any>{
    var updateAlertUrl = `${this.local_uri}/premium/kilns/${kiln_id}/alertsettings`;
    return this.http.put(updateAlertUrl, data, this.httpOptions())
      .toPromise()
  }

  public updateSingleUserAlertSettings(kiln_id, data): Promise<any>{
    var updateAlertUrl = `${this.local_uri}/premium/kilns/${kiln_id}/useralertsettings`;
    return this.http.put(updateAlertUrl, data, this.httpOptions())
      .toPromise()
  }

  public getAlerts(kiln_id): Promise<any>{
    var alertUrl = `${this.local_uri}/premium/kilns/${kiln_id}/alerts`;
    return this.http.get(alertUrl, this.httpOptions())
      .toPromise()
  }

  public markRead(kiln_id, alert_type, alert_id?): Promise<any>{
    var alertUrl = `${this.local_uri}/premium/kilns/${kiln_id}/alerts`;
    return this.http.post(alertUrl, {alert_type: alert_type, alert_id: alert_id}, this.httpOptions()).toPromise()
  }

  public verifyDevice(user, type = "email", body): Promise<any>{
    var alertUrl = `${this.local_uri}/users/premium/${user.id}/${type}/verify`;
    return this.http.post(alertUrl, body, this.httpOptions())
      .toPromise()
  }

  public confirmVerification(type = "email", body): Promise<any>{
    var alertUrl = `${this.local_uri}/users/premium/${type}/confirm`;
    return this.http.post(alertUrl, body, this.httpOptions())
      .toPromise()
  }

  public deletePhone(phone_number): Promise<any>{
    var alertUrl = `${this.local_uri}/users/premium/phone/delete`;
    return this.http.post(alertUrl, {phone: phone_number}, this.httpOptions()).toPromise()
  }

  public deleteDeviceFromUser(type, selected_device, user): Promise<any> {
    let email = user.emails.find((e) => e.default_email)
    if(type === 'phone') {
      var alertUrl = `${this.local_uri}/users/premium/phone/delete`;
      return this.http.post(alertUrl, {phone: selected_device.call_number, loginAs: (email.mail_to || user.username)}, this.httpOptions())
        .toPromise()
    } else {
      var alertUrl = `${this.local_uri}/users/premium/email/delete`;
      return this.http.post(alertUrl, {email: selected_device.mail_to, loginAs: (email.mail_to || user.username)}, this.httpOptions())
        .toPromise()
    }
  }

  public makeDeviceDefaultForUser(type, selected_device, user): Promise<any>{
    if(type === 'phone') {
      var alertUrl = `${this.local_uri}/users/premium/phone/default`;
      return this.http.post(alertUrl, {phone: selected_device.call_number, loginAs: (user.email || user.username)}, this.httpOptions())
        .toPromise()
    } else {
      var alertUrl = `${this.local_uri}/users/premium/email/default`;
      return this.http.post(alertUrl, {email: selected_device.mail_to, loginAs: (user.email || user.username)}, this.httpOptions())
        .toPromise()
    }
  }

  public sendTestAlert(kiln_mac, kiln_id, alert_type): Promise<any>{
    var alertUrl = `${this.local_uri}/premium/message/test`;
    let data = {alert_type: alert_type, mac_address: kiln_mac, serial_number: kiln_id};
    if(this.userService.isLoggedInAsAnotherUser()) {
      let loginData = this.userService.getLoginData().loggedInAs;
      data["loginAs"] = loginData && loginData.email;
    }
    return this.http.post(alertUrl, data, this.httpOptions())
      .toPromise()
  }

  httpOptions() {
    return {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': this.userService.getLoginData().token
      })
    }
  }
}
